<template>
    <div class="nongji">
        <van-search v-model="listQuery.imei" @search="onSearch" placeholder="请输入设备IMEI进行搜索" shape="round" background="transparent"/>
        
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text=""
                @load="getTableList"
                v-if="!isNoData"
                offset="50"
            >
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="top">
                        <div class="top-left">
                            <img src="../../../assets/images/nongji/icon_list_device.png"/>
                            <div class="content">
                                <p @click="seeDetails(item)">{{item.name}}</p>
                                <p>IMEI:{{item.imei}}</p>
                            </div>
                        </div>
                        <div class="top-right">
                            <p class="view-crop" v-if="item.isLease">可租借</p>
                            <p class="view-crop-no" v-else>不可租借</p>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="bottom-left">
                            <div class="desc">
                                <span>园区:</span>
                                <span>{{item.parkName}}</span>
                            </div>
                        </div>
                        <div class="bottom-right">
                            <p class="view-crop" @click="viewAgriculturalRecords(item.massifId)">作业记录</p>
                        </div>
                    </div>
                </div>
            </van-list>
            <div class="no-data" v-else>
                <div>
                    <img src="../../../assets/images/icon_nodata.png"/>
                </div>
                <div>
                    <span class="no-data-text">暂无数据</span>
                </div>
            </div>
        </van-pull-refresh>

        <router-link :to="{path: '/machineryMarket'}">
            <div class="market"></div>
        </router-link>
    </div>
</template>

<script>
import { deviceList } from '@/api/agriculturalMachinery/deviceManagement';
export default {
    data() {
        return {
            active: 0,
            keyword: '', // 搜索关键词
            loading: false,
            finished: false,
            refreshing: false,
            list: [],
            isNoData: false,//是否无数据
            listQuery: {
                imei: '',
                /**分页 */
                total: 0, //分页总数
                pageIndex : 1, //当前默认页码
                pageSize : 10, //每页数据量
                includeSub : false
            }
        };
    },
    mounted() {
        // this.getTableList();
    },
    methods: {
        /** 搜索 */
        onSearch() {
            this.getTableList('search');
        },
        /**
         * 获取表格数据
         */
        getTableList(val){
            this.loading = true;
            if(val == 'search'){
                this.listQuery.pageIndex =1 ;
                this.list = [];
            }
            deviceList(this.listQuery).then( res => {
                if (res.code == 200) {
                    if (res.data.length > 0) {
                        this.list = this.list.concat(res.data);
                        this.listQuery.pageIndex++
                    } else {
                        this.finished = true;
                    }
                    this.isNoData = this.list.length > 0 ? false : true;
                    this.listQuery.total = res.count || 0;
                    this.refreshing = false;
                    this.loading = false;
                }
            });
        },
        onRefresh() {
            this.list = [];
            this.finished = false;
            this.listQuery.pageIndex = 1;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.getTableList();
        },
        /** 查看农事记录 */
        viewAgriculturalRecords(id) {
            this.$router.push({name: 'agriculturalRecords', params: {massifId: id}})
        },
        /** 查看详情 */
        seeDetails(obj) {
            this.$router.push({name: 'machineryInfo', params: obj})
        },
    }
}
</script>

<style lang="scss" scoped>
    .nongji {
        padding: 14px 14px 64px;
        box-sizing: border-box;
        .item {
            position: relative;
            margin-bottom: 10px;
            padding: 12px;
            border-radius: 8px;
            background-color: #fff;
            .top {
                display: flex;
                // align-items: center;
                justify-content: space-between;
                .top-left {
                    display: flex;
                    align-items: center;
                    img {
                        width: 38px;
                        height: 38px;
                        margin-right: 12px;
                    }
                    .content {
                        p {
                            max-width: 180px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        p:nth-of-type(1) {
                            font-size: 14px;
                            font-weight: 500;
                            color: #4F4F4F;
                        }
                        p:nth-of-type(2) {
                            margin-top: 8px;
                            font-size: 12px;
                            color: #438EFD;
                        }
                    }
                }
                .top-right {
                    .view-crop {
                        padding: 0 5px;
                        line-height: 17px;
                        font-size: 12px;
                        color: #438EFD;
                        background: #D0E5FF;
                        border-radius: 20px;
                        border: 1px solid #4499FF;
                    }
                    .view-crop-no {
                        padding: 0 5px;
                        font-size: 12px;
                        color: #B7B7B7;
                        border-radius: 20px;
                        border: 1px solid #CDCDCD;
                    }
                    .view-crop:nth-of-type(2),
                    .view-crop:nth-of-type(3) {
                        margin-top: 8px;
                    }
                }
            }
            .bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 25px;
                .bottom-left {
                    .desc {
                        font-size: 14px;
                        color: #787878;
                        span {
                            margin-right: 8px;
                        }
                    }
                }
                .bottom-right {
                    .view-crop {
                        line-height: 17px;
                        font-size: 12px;
                        color: #438EFD;
                    }
                }
            }
        }
        .market {
            position: fixed;
            right: 0;
            bottom: 20%;
            width: 56px;
            height: 56px;
            background: url('../../../assets/images/nongji/market.png') no-repeat;
            background-size: 100% 100%;
        }
    }
</style>
<style lang="scss">
    .nongji {
        .van-tab--active .van-tab__text {
            color: #228EFF;
        }
    }
</style>