import request from '@/utils/request';
/**
 *          库存管理
 */
/** 库存列表 */
export function deviceList(data) {
    return request({
        url: '/cqjimi/deviceService/listPageDevice',//请求路径
        method: 'post',
        data
    });
}

/** 所以设备列表 */
export function listDevice(data) {
    return request({
        url: '/cqjimi/deviceService/listDevice',//请求路径
        method: 'post',
        data
    });
}

/** 库存管理修改 */
export function editDevicePort(data) {
    return request({
        url: '/cqjimi/deviceService/modifyDevice',//请求路径
        method: 'post',
        data,
    });
}


/** 库存模板下载 */
export function deviceDownloadTemplate(data) {
    return request({
        url: '/nj/device/downloadTemplate',//请求路径
        method: 'get',
        params: data,
        responseType: 'blob'    //重点重点，，位置要写对
    });
}


/** 批量导入 */
export function batchImportDevice(data) {
    return request({
        url: '/nj/device/import',//请求路径
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

/** 删除*/
export function deleteDevice(data) {
    return request({
        url: '/cqjimi/deviceService/deleteDevice',//请求路径
        method: 'post',
        data
    });
}

/** 新增设备 */
export function addDevice(data) {
    return request({
        url: '/device/add',//请求路径
        method: 'post',
        data
    });
}

/** 库存分配到组 */
export function assignToGroup(data) {
    return request({
        url: '/device/assignToGroup',//请求路径
        method: 'post',
        data
    });
}

/** 库存分配到机构 */
export function assignToOrg(data) {
    return request({
        url: '/device/assignToOrg',//请求路径
        method: 'post',
        data
    });
}

/** 设备开关机 */
export function deviceSwitch(data) {
    return request({
        url: '/device/switch',//请求路径
        method: 'post',
        data
    });
}


/** imei模糊匹配 */
export function deviceMatching(data) {
    return request({
        url: '/device/matching',//请求路径
        method: 'post',
        data,
    });
}


/** 每日报表导出 */
export function workDailyReportExport(data) {
    return request({
        url: '/work/dailyReportExport',//请求路径
        method: 'get',
        params: data,
        responseType: 'blob'    //重点重点，，位置要写对
    });
}


/** 每日报表导出 */
export function stockExport(data) {
    return request({
        url: '/device/stockExport',//请求路径
        method: 'post',
        data: data,
        responseType: 'blob'    //重点重点，，位置要写对
    });
}
/* 作业详情报表导出 */
export function jobDetails(data) {
    return request({
        url: '/work/overviewListExport ',//请求路径
        method: 'get',
        data: data,
        responseType: 'blob'    //重点重点，，位置要写对
    });
}
/** imei修改喷幅 */
export function imeiSetSpray(data) {
    return request({
        url: '/device/setSpray',//请求路径
        method: 'post',
        data,
    });
}

/* 获取配置管理数据 */
export function getConfigData(data) {
    return request({
        url: '/device/getDeviceDetail',//请求路径
        method: 'post',
        data,
    });
}
/** 确认修改详情 */
export function confirmModifyDetail(data) {
    return request({
        url: '/device/modifyDevice',//请求路径
        method: 'post',
        data,
    });
}
/** 上传图片 */
export function uploadImage(data) {
    return request({
        url: '/farm/file/deviceImage',//请求路径
        method: 'post',
        data,
    });
}